import React from "react";
import Polygon from "../../images/polygon.svg";
import UserImg from "../../images/user.png";
import { Avatar, Image ,Button,Menu, Dropdown} from "antd";
import { userDidLogout } from "../../reducer/user";
import { useTranslation } from "react-i18next";
import {LogoutOutlined,UnlockOutlined } from "@ant-design/icons"
import { AppState } from "../../store";
import { useDispatch, useSelector } from "react-redux";

export default function AccountButton() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const userInfo = useSelector((state: AppState) => state.user);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const items = [
    { label: t('logout'), key: 'logout',icon:<LogoutOutlined style={{ fontSize: '16px'}} />}, // remember to pass the key prop
    { label: t('change_password'), key: 'change_password',icon:<UnlockOutlined style={{ fontSize: '16px'}} />} // which is required
  ];

  const menuAction =(key:string)=>{
      if(key == 'change_password'){
        window.location.href="/change-password";
      }
      if(key == 'logout'){
        dispatch(userDidLogout());
        window.location.href="/";
      }
  }
  
  const menu = (
    <Menu items={items} onClick={(item:{key:string})=>{menuAction(item.key)}}/>
  );
  
  return (
    <div>
      <Avatar
        src={
          <Image
            src={UserImg}
            style={{
              width: 28,
            }}
          />
        }
      />
      <Dropdown overlay={menu} placement="bottomRight">
        <Button style={{border:"none",outline:"none",boxShadow:"none"}}><img src={Polygon} alt="user action" /></Button>
      </Dropdown>
    </div>
  );
}
