import { useState, useEffect } from "react";
import { Modal, message, Input } from "antd";
import { useTranslation } from "react-i18next";
import "./style.scss";
import DropShipApi from "../../api/DropShipApi";
import { ProcessStatus } from "../../entity/entity";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

const { TextArea } = Input;

interface Prop {
  show: boolean | undefined;
  data:
    | {
        gift_detail_title: string;
        dropship_code: string;
        supplier_note: string;
        id: number;
      }
    | undefined;
  spinFail: () => void;
  spin: () => void;
  changeProcessing: () => void;
}
export default function SuccessModal(prop: Prop) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [note, setNote] = useState(prop.data?.supplier_note);
  const userInfo = useSelector((state: AppState) => state.user);

  useEffect(() => {
    if (prop.show === undefined) {
      setIsModalVisible(false);
    } else {
      setIsModalVisible(true);
    }
  }, [prop.show]);
  useEffect(() => {
    setNote(prop.data?.supplier_note);
  }, [prop.data]);

  const { t } = useTranslation();

  const handleOk = async () => {
    if (!note) {
      const key = "updatable";
      message.error({ content: "Chưa nhập ghi chú!", key, duration: 2 });
      return false;
    }
    const response = await DropShipApi.update(
      {
        supplier_note: note,
        processing: ProcessStatus.DELIVERED,
        id: prop.data === undefined ? 0 : prop.data.id,
      },
      userInfo.token
    );
    if (response?.id && response.id) {
      prop.spin();
      prop.changeProcessing();
      setTimeout(() => {
        setIsModalVisible(false);
      }, 1000);
    } else {
      prop.spinFail();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title={t("delivery_success")}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      className="text-center confirm-popup success-modal"
      footer={null}
    >
      Vì đơn hàng không có mã vận đơn nên bạn vui lòng cập nhập thêm thông tin
      giao hàng bên dưới!
      <TextArea
        rows={4}
        style={{
          marginTop: "13px",
          borderRadius: "4px",
          background: "#D9D9D9",
        }}
        placeholder="Ghi chú của bạn"
        onChange={(e: any) => {
          setNote(e.target.value);
        }}
        value={note}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px",
          marginTop: "13px",
        }}
      >
        <button className="cancel-button" onClick={handleCancel}>
          {t("cancel")}
        </button>
        <button className="confirm-button" onClick={handleOk}>
          {t("confirm")}
        </button>
      </div>
    </Modal>
  );
}
