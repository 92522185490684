import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import "./style.scss";
import DropShipApi from "../../api/DropShipApi";
import { ProcessStatus } from "../../entity/entity";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

interface Prop {
  show: boolean | undefined;
  data: { gift_detail_title: string; id: number } | undefined;
  spinFail: () => void;
  spin: () => void;
  changeProcessing: () => void;
}
export default function OutOfStockConfirm(prop: Prop) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const userInfo = useSelector((state: AppState) => state.user);

  useEffect(() => {
    if (prop.show === undefined) {
      setIsModalVisible(false);
    } else {
      setIsModalVisible(true);
    }
  }, [prop.show]);
  const { t } = useTranslation();

  const handleOk = async () => {
    const response = await DropShipApi.update(
      {
        processing: ProcessStatus.OUTOFSTOCK,
        id: prop.data === undefined ? 0 : prop.data.id,
      },
      userInfo.token
    );
    if (response?.id && response.id) {
      prop.spin();
      prop.changeProcessing();
      setTimeout(() => {
        setIsModalVisible(false);
      }, 1000);
    } else {
      prop.spinFail();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title={t("out_of_stock_confirm")}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      className="text-center confirm-popup out-of-stock-modal"
      footer={null}
    >
      Mã đơn{" "}
      <b
        style={{ fontSize: "16px", color: "black", fontFamily: "Roboto-Bold" }}
      >
        {prop.data?.id}
      </b>{" "}
      Sản phẩm{" "}
      <b
        style={{ fontSize: "16px", color: "black", fontFamily: "Roboto-Bold" }}
      >
        {prop.data?.gift_detail_title}
      </b>{" "}
      này của bạn đã hết hàng. UrBox sẽ chuyển cho một đơn vị cung cấp khác.
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <button className="cancel-button" onClick={handleCancel}>
          {t("cancel")}
        </button>
        <button className="confirm-button" onClick={handleOk}>
          {t("out_of_stock")}
        </button>
      </div>
    </Modal>
  );
}
