import { Badge } from "antd";
import { BellOutlined } from "@ant-design/icons";

export default function Notification() {
  return (
    <Badge count={1} size="small">
      <BellOutlined style={{ fontSize: "20px" }} />
    </Badge>
  );
}
