import axios from "axios";
const getHeaders = (token = "") => ({
  Accept: "application/json",
  "Content-Type": "application/json;charset=UTF-8",
  Authorization: `Bearer ${token}`,
});
const FILE_SERVER_URL = process.env.REACT_APP_FILE_SERVER_URL;
const BASE_URL = process.env.REACT_APP_ORDER_API_URL;
const Service = {
  async getRequestData(url: string, params?: Record<string, any>, token = "") {
    try {
      const response = await axios({
        method: "get",
        url: BASE_URL + url,
        headers: getHeaders(token),
        params,
      });

      return response.data;
    } catch (e) {
      console.log(e);

      return null;
    }
  },
  async postRequestData(url: string, data: Record<string, any>, token = "") {
    let result;
    await axios
      .post(FILE_SERVER_URL + url, data, { headers: getHeaders(token) })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => (result = error));
    return result;
  },

  async putRequestData(url: string, data: Record<string, any>, token?: string) {
    const result = await axios
      .put(url, data, {
        headers: { "Content-Type": "application/vnd.ms-excel" },
      })
      .then((response) => response)
      .catch((error) => console.log(error));
    return result;
  },
  async postFileUrl(url: string, data: Record<string, any>, token = "") {
    let result;
    await axios
      .post(BASE_URL + url, data, { headers: getHeaders(token) })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => (result = error));
    return result;
  },
};

export default Service;
