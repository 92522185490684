import { createAction, createReducer } from '@reduxjs/toolkit';
import { User } from '../entity/entity';

export const userDidLogin = createAction<Partial<User>>('user/didLogin');
export const userDidLogout = createAction('user/didLogout');
export const userDidChange = createAction<Partial<User>>(
  'user/didChange'
);

export const userReducer = createReducer(
  {} as Partial<User>,
  (builder) => {
    builder
      .addCase(userDidLogin, (state, action) => {
        return action.payload;
      })
      .addCase(userDidLogout, () => {
        return {};
      })
      .addCase(userDidChange, (state, action) => {
        return { ...state, ...action.payload };
      });
  }
);
