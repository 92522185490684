import FileService from "./FileService";

const FileApi = {
  putFile: async (url, file) => {
    try {
      const result = await FileService.putRequestData(url, file);

      return result;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  postFile: async (fileName) => {
    try {
      const result = await FileService.postRequestData("/" + fileName, {});

      return result;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  postUrlFile: async (url, credentials, token) => {
    console.log(url);
    console.log(credentials);
    try {
      const result = await FileService.postFileUrl(url, credentials, token);
      return result;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
};

export default FileApi;
