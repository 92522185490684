import { useTranslation } from "react-i18next";
import { Form, Button } from "antd";
import UserApi from "../../api/UserApi";
import { useState } from "react";

export default function SendEmailConfirmSuccess() {
  const { t } = useTranslation();
  const [disable, setDisable] = useState(false);

  if (!localStorage.getItem("emailSend")) {
    window.location.href = "/";
  }
  const submitForm = async () => {
    const response = await UserApi.send_email_reset_password({
      email: localStorage.getItem("emailSend"),
    });
    setDisable(true);
  };

  return (
    <div>
      <div
        style={{
          fontSize: 24,
          fontWeight: 700,
          fontFamily: "Roboto-Bold",
        }}
      >
        {t("email_sent")}
      </div>

      <div>{t("email_recover_sent")}</div>
      <p style={{ color: "#1890FF" }}>{localStorage.getItem("emailSend")}</p>
      <div
        style={{
          backgroundColor: "#EAEAF4",
          marginTop: "13px",
          marginBottom: "16px",
          height: "1px",
        }}
      ></div>
      <p style={{ color: "#8C8C8C", fontSize: "14px" }}>
        {t("re_send_email_text")}
      </p>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              height: "40px",
              backgroundColor: disable ? "" : "#720D5D",
              color: disable ? "" : "white",
            }}
            onClick={submitForm}
            disabled={disable}
          >
            {t("re_send")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
