import { Table, message } from "antd";
import { useTranslation } from "react-i18next";
import { Menu, Dropdown } from "antd";
import { ReactComponent as DownloadImg } from "../../images/download.svg";
import { ReactComponent as ReturnImg } from "../../images/return.svg";
import { ReactComponent as NoteImg } from "../../images/note.svg";
import { ReactComponent as XImg } from "../../images/close.svg";
import { ReactComponent as SuccessImg } from "../../images/check-circle.svg";
import { ReactComponent as DeliveryImg } from "../../images/delivery-white.svg";
import { ReactComponent as FailImg } from "../../images/fail.svg";
import OutOfStockConfirm from "./OutOfStockConfirm";
import UpdateDeliveryCode from "./UpdateDeliveryCode";
import DeliveryStatus from "./DeliveryStatus";
import ReturnModal from "./ReturnModal";
import SuccessModal from "./SuccessModal";
import NoteModal from "./NoteModal";
import DateSelect from "../common/DateSelect/DateSelect";
import { useState, useEffect, useCallback } from "react";
import "./style.scss";
import { OrderListBy } from "../../entity/entity";
import DropShipApi from "../../api/DropShipApi";
import * as FileSaver from "file-saver";
import _ from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import { ProcessStatus, ProcessStatusText } from "../../entity/entity";
import { AppState } from "../../store";
import { useSelector } from "react-redux";
import Excel from "exceljs";
import Sort from "./Sort";
import moment, { Moment } from "moment";

const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
const { debounce } = _;

interface Prop {
  typeView: OrderListBy | undefined;
  search: string;
  export: boolean | undefined;
}

const key = "updatable";
const openMessage = () => {
  message.loading({ content: "Update...", key });
  setTimeout(() => {
    message.success({ content: "Success!", key, duration: 2 });
  }, 1000);
};
const openFail = () => {
  message.loading({ content: "Update...", key });
  setTimeout(() => {
    message.error({ content: "Fail!", key, duration: 2 });
  }, 1000);
};

export default function DataGrid(prop: Prop) {
  const { t } = useTranslation();
  const [rowSelected, setRowSelected] = useState(0);
  const [disableAllAction, setDisableAllAction] = useState(false);
  const [page, setPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [spinning, setSpinning] = useState(true);
  const userInfo = useSelector((state: AppState) => state.user);

  const [sort, setSort] = useState("");
  const [sortType, setSortType] = useState("");

  const [outOfStockShow, setOutOfStockShow] = useState<boolean | undefined>(
    undefined
  );
  const [returnModalShow, setReturnModalShow] = useState<boolean | undefined>(
    undefined
  );
  const [successModalShow, setSuccessModalShow] = useState<boolean | undefined>(
    undefined
  );
  const [noteModalShow, setNoteModalShow] = useState<boolean | undefined>(
    undefined
  );

  function showModalOutOfStock() {
    setOutOfStockShow(outOfStockShow ? false : true);
  }
  function showReturnModal() {
    setReturnModalShow(returnModalShow ? false : true);
  }
  function showSuccessModal() {
    setSuccessModalShow(successModalShow ? false : true);
  }
  function showNoteModal() {
    setNoteModalShow(noteModalShow ? false : true);
  }
  function reload() {
    DropShipList(prop.search);
  }

  const [data, setData] = useState([]);

  async function pick(data: any) {
    const dataConvert = [];
    for (var i in data) {
      const pickData = _.pick(data[i], [
        "cart_detail_id",
        "ub_code",
        "order_date",
        "dropship_code",
        "estimated_delivery_time",
        "gift_detail_title",
        "customer_fullname",
        "customer_phone",
        "customer_email",
        "delivery_address",
        "customer_note",
        "processing",
        "supplier_note",
      ]);
      dataConvert.push(pickData);
    }
    return dataConvert;
  }
  const dropShipUpdateProcessing = async (
    id: number,
    processStatus: number,
    note?: string
  ) => {
    const param = {
      processing: processStatus,
      id: id,
    };
    const paramSend = note ? { ...param, supplier_note: note } : { ...param };

    const response = await DropShipApi.update(paramSend, userInfo.token);
    if (response?.id && response.id) {
      openMessage();
      reload();
    } else {
      openFail();
    }
  };

  const onChangeEstimatedTime = async (
    value: Moment | null,
    dateString: string,
    id: number
  ) => {
    const paramSend = value
      ? { estimated_delivery_time: value?.unix(), id: id }
      : { estimated_delivery_time: 0, id: id };

    const response = await DropShipApi.update(paramSend, userInfo.token);
    if (response?.id && response.id) {
      openMessage();
      reload();
    } else {
      openFail();
    }
  };

  async function exportExcel(data: any) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("data");

    worksheet.columns = [
      { header: "ID", key: "id", width: 10 },
      { header: t("order_code"), key: "cart_detail_id", width: 10 },
      { header: t("urbox_code"), key: "ub_code", width: 32 },
      { header: t("order_time"), key: "order_date", width: 15 },
      { header: t("deliver_code"), key: "dropship_code", width: 10 },
      {
        header: t("estimated_delivery_time"),
        key: "estimated_delivery_time",
        width: 32,
      },
      { header: t("product_name"), key: "gift_detail_title", width: 15 },
      { header: t("customer_name"), key: "customer_fullname", width: 10 },
      { header: t("customer_phone"), key: "customer_phone", width: 32 },
      { header: t("receiver_email"), key: "customer_email", width: 15 },
      { header: t("receiver_address"), key: "delivery_address", width: 10 },
      { header: t("customer_note"), key: "customer_note", width: 32 },
      { header: t("order_status"), key: "status", width: 15 },
      { header: t("supplier_note"), key: "supplier_note", width: 15 },
    ];
    for (var i = 0; i < data.length; i++) {
      worksheet.addRow(data[i]);
      const row = `M${i + 2}`;
      worksheet.getCell(row).dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [
          '"Có hàng,Đang giao hàng,Chờ có hàng,Thành công,Trả hàng,Hết hàng"',
        ], //'"One,Two,Three,Four"'
        showErrorMessage: true,
        errorStyle: "error",
        error: "The value Valid",
      };
    }
    const fileName = "Đơn hàng UrBox " + moment().format("HH:mm DD/MM/YY");
    saveFile(fileName, workbook);
  }
  async function saveFile(fileName: string, workbook: any) {
    const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
    FileSaver.saveAs(
      new Blob([xls64], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      }),
      fileName
    );
  }

  useEffect(() => {
    setPage(1);
    setPageTotal(0);
    debounceSearch(prop.search);
  }, [prop.search]);

  const debounceSearch = useCallback(
    debounce((key) => DropShipList(key), 1000),
    []
  );

  const currencyFormat = (num: number) => {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " vnd";
  };

  const DropShipList = async (search: string) => {
    setSpinning(true);
    const requestParam = {
      filter: prop.typeView,
      page: page,
      page_limit: itemPerPage,
      tax_id: userInfo.tax_id,
      sort: sort,
      sort_type: sortType,
      status: 2
    };
    if (userInfo.username === "master") {
      delete requestParam["tax_id"]
    }
    var request = {};
    if (search) {
      request = {
        ...requestParam,
        search: search,
      };
    } else {
      request = { ...requestParam };
    }
    console.log(userInfo)
    const response = await DropShipApi.getList(request, userInfo.token);

    if (response?.data) {
      for (var i = 0; i < response.data.length; i++) {
        response.data[i]["key"] = i;
        var date = new Date(response.data[i]["created_at"] * 1000);
        response.data[i]["order_date"] = Intl.DateTimeFormat("vi-VN", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }).format(date);
        if (response.data[i]["estimated_delivery_time"]) {
          var estimated_date = new Date(
            response.data[i]["estimated_delivery_time"] * 1000
          );
          response.data[i]["estimated_delivery_time"] = Intl.DateTimeFormat(
            "vi-VN",
            {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            }
          ).format(estimated_date);
        } else {
          response.data[i]["estimated_delivery_time"] = "";
        }

        response.data[i]["status"] = ProcessStatusText(
          response.data[i]["processing"]
        );

        response.data[i]["price"] = response.data[i]["price"]
          ? currencyFormat(response.data[i]["price"])
          : "";
      }
      setData(response.data);
      setSpinning(false);
      setPageTotal(response?.total ? response.total : 0);
    }
  };
  const DropShipListExport = async (search: string, token: string) => {
    const requestParam = {
      filter: prop.typeView,
      page: 1,
      page_limit: 100000,
      tax_id: userInfo.tax_id,
      status: 2
    };
    if (userInfo.username === "master") {
      delete requestParam["tax_id"]
    }
    var request = {};
    if (search) {
      request = {
        ...requestParam,
        search: search,
      };
    } else {
      request = { ...requestParam };
    }

    const response = await DropShipApi.getList(request, token);
    if (response?.data) {
      for (var i = 0; i < response.data.length; i++) {
        response.data[i]["key"] = i;
        var date = new Date(response.data[i]["created_at"] * 1000);
        response.data[i]["order_date"] = Intl.DateTimeFormat("vi-VN", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }).format(date);

        var estimated_date = new Date(
          response.data[i]["estimated_delivery_time"] * 1000
        );
        if (response.data[i]["estimated_delivery_time"] > 0) {
          response.data[i]["estimated_delivery_time"] = Intl.DateTimeFormat(
            "vi-VN",
            {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            }
          ).format(estimated_date);
        } else {
          response.data[i]["estimated_delivery_time"] = "";
        }

        response.data[i]["status"] = ProcessStatusText(
          response.data[i]["processing"]
        );
      }
      return response.data;
    }
  };

  const [isMount, setIsMount] = useState(true);
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    setPage(1);
    setPageTotal(0);
    DropShipList(prop.search);
  }, [prop.typeView]);

  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    DropShipList(prop.search);
  }, [page, sort, sortType]);

  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    if (page > 1) {
      setPage(1);
      setPageTotal(1);
    } else {
      DropShipList(prop.search);
    }
  }, [itemPerPage]);

  useEffect(() => {
    setDisableAllAction(false);
    if (
      data.length > 0 &&
      (data[rowSelected]["processing"] === ProcessStatus.DELIVERED ||
        data[rowSelected]["processing"] === ProcessStatus.RETURNED ||
        data[rowSelected]["processing"] === ProcessStatus.FAILED ||
        data[rowSelected]["processing"] === ProcessStatus.OUTOFSTOCK)
    ) {
      setDisableAllAction(true);
    }
  }, [rowSelected]);

  useEffect(() => {
    const Export = async () => {
      if (prop.export !== undefined) {
        const result = await DropShipListExport(prop.search, userInfo.token);
        exportExcel(result);
      }
    };
    Export();
  }, [prop.export]);

  const menu = (
    <Menu style={{ width: 178 }} className="action-menu">
      <Menu.Item key="0" icon={<DownloadImg />}>
        <a
          href="/"
          onClick={(e: any) => {
            e.preventDefault();
            exportExcel([data[rowSelected]]);
          }}
        >
          {t("download_list")}
        </a>
      </Menu.Item>

      {data[rowSelected] &&
      data[rowSelected]["processing"] !== ProcessStatus.DELIVERED &&
      !disableAllAction ? (
        <Menu.Item key="1" icon={<SuccessImg />}>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              if (!data[rowSelected]["dropship_code"]) {
                showSuccessModal();
              } else {
                dropShipUpdateProcessing(
                  data[rowSelected]["id"],
                  ProcessStatus.DELIVERED
                );
              }
            }}
          >
            {t("success")}
          </a>
        </Menu.Item>
      ) : (
        ""
      )}
      {data[rowSelected] &&
      data[rowSelected]["processing"] !== ProcessStatus.DELIVERING &&
      !disableAllAction ? (
        <Menu.Item key="2" icon={<DeliveryImg />}>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              dropShipUpdateProcessing(
                data[rowSelected]["id"],
                ProcessStatus.DELIVERING,
                "Nhà cung cấp tự vận chuyển"
              );
            }}
          >
            {t("supplier_delivery")}
          </a>
        </Menu.Item>
      ) : (
        ""
      )}
      {data[rowSelected] &&
      data[rowSelected]["processing"] !== ProcessStatus.RETURNED &&
      !disableAllAction ? (
        <Menu.Item
          key="3"
          style={{ height: "40px", lineHeight: "40px" }}
          icon={<ReturnImg />}
          className="icon-custom"
        >
          <a
            href="/"
            onClick={(e) => {
              showReturnModal();
              e.preventDefault();
            }}
          >
            {t("return_order")}
          </a>
        </Menu.Item>
      ) : (
        ""
      )}
      {data[rowSelected] &&
      data[rowSelected]["processing"] !== ProcessStatus.FAILED &&
      !disableAllAction ? (
        <Menu.Item key="4" icon={<FailImg />} style={{ color: "#AC0000" }}>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              dropShipUpdateProcessing(
                data[rowSelected]["id"],
                ProcessStatus.FAILED
              );
            }}
          >
            {t("fail")}
          </a>
        </Menu.Item>
      ) : (
        ""
      )}
      <Menu.Item
        key="5"
        style={{ height: "40px", lineHeight: "40px" }}
        icon={<NoteImg />}
        className="icon-custom"
      >
        <a
          href="/"
          onClick={(e) => {
            showNoteModal();
            e.preventDefault();
          }}
        >
          {t("note")}
        </a>
      </Menu.Item>
      {false ? (
        <Menu.Item
          key="6"
          style={{ height: "40px", lineHeight: "40px", color: "red" }}
          icon={<XImg />}
        >
          <a
            href="/"
            onClick={(e) => {
              showModalOutOfStock();
              e.preventDefault();
            }}
          >
            {t("out_of_stock")}
          </a>
        </Menu.Item>
      ) : (
        ""
      )}
    </Menu>
  );
  const columns: any = [
    {
      title: () => {
        return (
          <Sort
            changeSort={(sort: string) => {
              setSort("cart_detail_id");
              setSortType(sort);
            }}
            title={t("order_code")}
          />
        );
      },
      width: 100,
      dataIndex: "cart_detail_id",
      key: "cart_detail_id",
    },
    {
      title: t("urbox_code"),
      width: 100,
      dataIndex: "ub_code",
      key: "ub_code",
    },
    {
      title: () => {
        return (
          <Sort
            changeSort={(sort: string) => {
              setSort("created_at");
              setSortType(sort);
            }}
            title={t("order_time")}
          />
        );
      },
      width: 110,
      dataIndex: "order_date",
      key: "order_date",
    },
    {
      title: t("type_deliver_code"),
      dataIndex: "dropship_code",
      key: "dropship_code",
      width: 200,
      render: (code: string, data: any) => (
        <UpdateDeliveryCode
          code={code}
          data={data}
          key={data.id}
          spin={() => {
            openMessage();
            reload();
          }}
          spinFail={() => {
            openFail();
          }}
        />
      ),
    },
    {
      title: () => {
        return (
          <Sort
            changeSort={(sort: string) => {
              setSort("estimated_delivery_time");
              setSortType(sort);
            }}
            title={t("estimated_delivery_time")}
          />
        );
      },
      width: 160,
      dataIndex: "estimated_delivery_time",
      key: "estimated_delivery_time",
      render: (code: string, data: any) => (
        <DateSelect
          date={data.estimated_delivery_time}
          id={data.id}
          onSelected={onChangeEstimatedTime}
        />
      ),
    },
    {
      title: () => {
        return (
          <Sort
            changeSort={(sort: string) => {
              setSort("processing");
              setSortType(sort);
            }}
            title={t("status")}
          />
        );
      },
      width: 170,
      dataIndex: "processing",
      key: "processing",
      render: (processing: number, data: any) => (
        <DeliveryStatus
          processing={processing}
          dropshipCode={data.dropship_code}
        />
      ),
    },
    {
      title: () => {
        return (
          <Sort
            changeSort={(sort: string) => {
              setSort("product_name");
              setSortType(sort);
            }}
            title={t("product_name")}
          />
        );
      },
      dataIndex: "gift_detail_title",
      key: "gift_detail_title",
      width: 150,
    },
    {
      title: () => {
        return (
          <Sort
            changeSort={(sort: string) => {
              setSort("price");
              setSortType(sort);
            }}
            title={t("price")}
          />
        );
      },
      dataIndex: "price",
      key: "price",
      width: 150,
    },
    {
      title: () => {
        return (
          <Sort
            changeSort={(sort: string) => {
              setSort("customer_fullname");
              setSortType(sort);
            }}
            title={t("customer_name")}
          />
        );
      },
      dataIndex: "customer_fullname",
      key: "customer_fullname",
      width: 150,
    },
    {
      title: t("receiver_phone"),
      dataIndex: "customer_phone",
      key: "customer_phone",
      width: 115,
    },
    {
      title: () => {
        return (
          <Sort
            changeSort={(sort: string) => {
              setSort("email");
              setSortType(sort);
            }}
            title={t("receiver_email")}
          />
        );
      },
      dataIndex: "customer_email",
      key: "customer_email",
      width: 150,
    },
    {
      title: t("receiver_address"),
      dataIndex: "delivery_address",
      key: "delivery_address",
      width: 150,
    },
    {
      title: t("customer_note"),
      dataIndex: "customer_note",
      key: "customer_note",
      width: 150,
    },

    {
      title: t("supplier_note"),
      dataIndex: "supplier_note",
      key: "supplier_note",
      width: 150,
    },

    {
      title: t("action"),
      key: "operation",
      fixed: "right",
      width: 100,
      dataIndex: "key",
      render: (key: number) => (
        <Dropdown overlay={menu} trigger={["click"]}>
          <a
            href="/"
            className="ant-dropdown-link"
            onClick={(e) => {
              setRowSelected(key);
              e.preventDefault();
            }}
          >
            {t("action")}
          </a>
        </Dropdown>
      ),
    },
  ];
  return (
    <>
      <div style={{ position: "relative" }}>
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: 1500, y: 500 }}
          pagination={{
            showQuickJumper: true,
            onChange: (newPage, newPageSize) => {
              if (itemPerPage === newPageSize) {
                setPage(newPage);
              } else {
                setItemPerPage(newPageSize);
              }
            },
            total: pageTotal,
            current: page,
          }}
          loading={{ indicator: antIcon, spinning: spinning }}
          style={{ position: "relative" }}
        />
        <div
          style={{ position: "absolute", bottom: "20px", paddingLeft: "10px" }}
        >
          {t("display")} <span style={{ color: "red" }}>{pageTotal}</span>{" "}
          {t("result")}
        </div>
      </div>

      <OutOfStockConfirm
        show={outOfStockShow}
        data={data[rowSelected]}
        spin={() => {
          openMessage();
        }}
        spinFail={() => {
          openFail();
        }}
        changeProcessing={() => {
          reload();
        }}
      />
      <ReturnModal
        show={returnModalShow}
        data={data[rowSelected]}
        spin={() => {
          openMessage();
        }}
        spinFail={() => {
          openFail();
        }}
        changeProcessing={() => {
          reload();
        }}
      />
      <SuccessModal
        show={successModalShow}
        data={data[rowSelected]}
        spin={() => {
          openMessage();
        }}
        spinFail={() => {
          openFail();
        }}
        changeProcessing={() => {
          reload();
        }}
      />
      <NoteModal
        show={noteModalShow}
        data={data[rowSelected]}
        spin={() => {
          openMessage();
        }}
        spinFail={() => {
          openFail();
        }}
      />
    </>
  );
}
