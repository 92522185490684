import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import { ReactComponent as Box } from "../../images/box.svg";
import "./style.scss";

const TopicMenu = ({ topics, selectedKey, changeSelectedKey }) => {
  const { t } = useTranslation();
  return (
    <Menu
      mode="inline"
      selectedKeys={[selectedKey]}
      className="manage-order-menu"
      style={{ position: "sticky", top: "72px" }}
    >
      <Menu.Item
        key={0}
        onClick={changeSelectedKey}
        style={{ marginTop: "0px", marginBottom: "0px", paddingRight: "0px" }}
        icon={<Box />}
      >
        <a href="/manage-order">{t("manage_order")}</a>
      </Menu.Item>
    </Menu>
  );
};

export default TopicMenu;
