import { ReactComponent as StatusChecked } from "../../images/status-checked.svg";
import { ReactComponent as DeliveringIcon } from "../../images/truck-icon.svg";
import { ReactComponent as ReturnIcon } from "../../images/status-return.svg";
import { ReactComponent as CancelIcon } from "../../images/Cancel.svg";
import { ReactComponent as TimerIcon } from "../../images/timer.svg";
import { ReactComponent as FailIcon } from "../../images/fail.svg";
import { useTranslation } from "react-i18next";
import { ProcessStatus, ProcessStatusText } from "../../entity/entity";
import "./style.scss";

interface Prop {
  processing: number;
  dropshipCode: any;
}
export default function DeliveryStatus(prop: Prop) {
  const { t } = useTranslation();

  if (!prop.dropshipCode && prop.processing === ProcessStatus.CONFIRMED) {
    return (
      <div className="waiting-process">
        <TimerIcon />
        {ProcessStatusText(0)}
      </div>
    );
  }

  if (prop.processing === ProcessStatus.DELIVERED) {
    return (
      <div className="delivery-success">
        <StatusChecked />
        {ProcessStatusText(ProcessStatus.DELIVERED)}
      </div>
    );
  }
  if (prop.processing === ProcessStatus.DELIVERING) {
    return (
      <div className="delivering-process">
        <DeliveringIcon />
        {ProcessStatusText(ProcessStatus.DELIVERING)}
      </div>
    );
  }
  if (prop.processing === ProcessStatus.RETURNED) {
    return (
      <div className="return-process">
        <ReturnIcon />
        {ProcessStatusText(ProcessStatus.RETURNED)}
      </div>
    );
  }
  if (prop.processing === ProcessStatus.OUTOFSTOCK) {
    return (
      <div className="outofstock-process">
        <CancelIcon />
        {ProcessStatusText(ProcessStatus.OUTOFSTOCK)}
      </div>
    );
  }

  if (prop.processing === ProcessStatus.FAILED) {
    return (
      <div className="fail-process">
        <FailIcon />
        {ProcessStatusText(ProcessStatus.FAILED)}
      </div>
    );
  }
  return (
    <div className="delivering-process">
      <DeliveringIcon />
      {ProcessStatusText(prop.processing)}
    </div>
  );
}
