import { DatePicker } from "antd";
import moment from "moment";
import "./style.css";
import { Moment } from "moment";
const dateFormat = "DD/MM/YYYY";

interface Prop {
  date: string;
  id: number;
  onSelected: (value: Moment | null, dateString: string, id: number) => void;
}
export default function DateSelect(prop: Prop) {
  return prop.date ? (
    <DatePicker
      defaultValue={moment(prop.date, dateFormat)}
      format={dateFormat}
      className="date-select"
      onChange={(value: Moment | null, dateString: string) => {
        prop.onSelected(value, dateString, prop.id);
      }}
      key={prop.id}
    />
  ) : (
    <DatePicker
      format={dateFormat}
      className="date-select"
      onChange={(value: Moment | null, dateString: string) => {
        prop.onSelected(value, dateString, prop.id);
      }}
    />
  );
}
