import { ReactComponent as SortIcon } from "../../images/sort.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface Prop {
  changeSort: (sort: string) => void;
  title: string;
}
export default function Sort(prop: Prop) {
  const { t } = useTranslation();
  const [sort, setSort] = useState("desc");
  return (
    <div style={{ display: "flex", height: "12px !important" }}>
      <div
        style={{
          fontWeight: 700,
          fontFamily: "Roboto-Bold",
        }}
      >
        {prop.title}
      </div>
      <div
        style={{ marginLeft: "3px", cursor: "pointer" }}
        onClick={() => {
          prop.changeSort(sort === "desc" ? "asc" : "desc");
          setSort(sort === "desc" ? "asc" : "desc");
        }}
      >
        {sort === "desc" ? (
          <SortIcon />
        ) : (
          <SortIcon style={{ transform: "matrix(1, 0, 0, -1, 0, 0)" }} />
        )}
      </div>
    </div>
  );
}
