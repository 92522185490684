import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Form, Button } from "antd";
import { WarningOutlined } from '@ant-design/icons';

export default function ResetSuccess() {
  const { t } = useTranslation();

  return (
    <div>
    <div
      style={{
        fontSize: 24,
        fontWeight: 700,
        fontFamily: "Roboto-Bold",
        color: "#1B903C",
      }}
    >
      <WarningOutlined style={{color: "#d9534f"}}/>
    </div>

    <p>{t("expired_time")}</p>
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
    >
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            width: "100%",
            height: "40px",
            backgroundColor: "#720D5D",
            color: "white",
            marginTop:30
          }}
        >
          <Link
                to="/"
              >
                {t("goto_login_form")}
          </Link>
        </Button>
      </Form.Item>
    </Form>
  </div>
  );
}
