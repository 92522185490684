import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './locales/i18n';
import { persistor, store } from './store';

function Application() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor} >
            <App />
        </PersistGate>
      </Provider>
    );
  }

ReactDOM.render(<Application />, document.getElementById("root"));
