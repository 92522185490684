import BackgroundImage from "../../images/login-bg.png";
import Logo from "../../components/logo";
import Login from "./Login";
import Expired from "./Expired";
import SendEmailConfirmReset from "./SendEmailConfirmReset";
import SendEmailConfirmSuccess from "./SendEmailConfirmSuccess";
import ChangePassword from "./ChangePassword";
import ResetSuccess from "./ResetSuccess";
import ResetPassword from "./ResetPassword";
import "../../fonts/font.css";
import { useLocation } from "react-router-dom";
import "./Style.scss";

export function GetBodyModal() {
  const location = useLocation();
  const path = location.pathname;
  if (path === "/") return <Login />;
  if (path === "/send-email-confirm") return <SendEmailConfirmReset />;
  if (path === "/expired") return <Expired />;
  if (path === "/change-password") return <ChangePassword />;
  if (path === "/send-email-confirm-success")
    return <SendEmailConfirmSuccess />;
  if (path === "/reset-password") return <ResetPassword />;
  return <ResetSuccess />;
}

export default function AuthenticationPage() {
  const bodyModal = GetBodyModal();
  const location = useLocation();
  const path = location.pathname;
  var modalHeight = "";
  switch (path) {
    case "/send-email-confirm":
      modalHeight = "460px";
      break;
    case "/send-email-confirm-success":
      modalHeight = "440px";
      break;
    case "/reset-password":
      modalHeight = "580px";
      break;
      case "/change-password":
        modalHeight = "640px";
        break;  
    case "/reset-success":
      modalHeight = "380px";
      break;
    case "/expired":
        modalHeight = "350px";  
      break;
    default:
      modalHeight = "520px";
      break;
  }

  return (
    <div>
      <img
        alt="bg"
        src={BackgroundImage}
        style={{
          height: "90%",
          position: "absolute",
          right: 0,
          bottom: 0,
        }}
      />
      <div className="authen-modal" style={{ height: modalHeight }}>
        <div className="body-modal">
          <Logo />
          <div style={{ height: "22px" }}></div>
          {bodyModal}
        </div>

        <div
          style={{
            display: "flex",
            fontSize: "14px",
            width: "324px",
            paddingTop: "30px",
            left: 0,
            right: 0,
            margin: "0px auto",
            position: "absolute",
            bottom: "20px",
          }}
        >
          <div style={{ width: "46%" }}>
            Hotline:{" "}
            <a
              href="tel:1900299232"
              style={{ color: "#720D5E", textDecoration: "none" }}
            >
              1900 299 232
            </a>
          </div>
          <div style={{ marginLeft: "5px", marginRight: "5px", width: "2%" }}>
            |
          </div>
          <div style={{ float: "right", width: "52%", textAlign: "right" }}>
            Mail: <span style={{ color: "#3490FF" }}>customer@urbox.vn</span>
          </div>
        </div>
      </div>
    </div>
  );
}
