import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import "./style.scss";
import DropShipApi from "../../api/DropShipApi";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
const { TextArea } = Input;

interface Prop {
  show: boolean | undefined;
  data:
    | {
        gift_detail_title: string;
        dropship_code: string;
        supplier_note: string;
        id: number;
      }
    | undefined;
  spinFail: () => void;
  spin: () => void;
}
export default function NoteModal(prop: Prop) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [note, setNote] = useState(prop.data?.supplier_note);
  const userInfo = useSelector((state: AppState) => state.user);

  useEffect(() => {
    if (prop.show === undefined) {
      setIsModalVisible(false);
    } else {
      setIsModalVisible(true);
    }
  }, [prop.show]);

  useEffect(() => {
    setNote(prop.data?.supplier_note);
  }, [prop.data]);

  const { t } = useTranslation();

  const handleOk = async () => {
    const response = await DropShipApi.update(
      {
        supplier_note: note,
        id: prop.data === undefined ? 0 : prop.data.id,
      },
      userInfo.token
    );
    if (response?.id && response.id) {
      prop.spin();
      setTimeout(() => {
        setIsModalVisible(false);
      }, 1000);
    } else {
      prop.spinFail();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title={t("note")}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      className="text-center confirm-popup"
      footer={null}
    >
      Ghi chú cho sản phẩm{" "}
      <b
        style={{ fontSize: "16px", color: "black", fontFamily: "Roboto-Bold" }}
      >
        {prop.data?.dropship_code} - {prop.data?.gift_detail_title}
      </b>
      <TextArea
        rows={4}
        style={{
          marginTop: "13px",
          borderRadius: "4px",
          background: "#D9D9D9",
        }}
        placeholder="Ghi chú của bạn"
        value={note}
        onChange={(e: any) => {
          setNote(e.target.value);
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px",
          marginTop: "13px",
        }}
      >
        <button className="cancel-button" onClick={handleCancel}>
          {t("cancel")}
        </button>
        <button className="confirm-button" onClick={handleOk}>
          {t("confirm")}
        </button>
      </div>
    </Modal>
  );
}
