import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { Upload, message, Button, Modal, Progress } from "antd";
import FileApi from "../../api/FileApi";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

const { Dragger } = Upload;

interface Prop {
  show: boolean | undefined;
}
export default function UploadModal(prop: Prop) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalConfirmVisible, setModalConfirmVisible] = useState(false);

  const [file, setFile] = useState<File | undefined>(undefined);
  const [percent, setPercent] = useState(0);
  const userInfo = useSelector((state: AppState) => state.user);

  useEffect(() => {
    if (prop.show === undefined) {
      setIsModalVisible(false);
    } else {
      setIsModalVisible(true);
    }
  }, [prop.show]);

  useEffect(() => {
    setPercent(0);
    setFile(undefined);
  }, [isModalVisible]);

  const { t } = useTranslation();

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const upload = async (file: File) => {
    const response = await FileApi.postFile(file.name);

    if (response && response["file_url"]) {
      const putResponse = await FileApi.putFile(response["url"], file);
      if (putResponse && putResponse["status"] === 200) {
        const putUrlResponse = await FileApi.postUrlFile(
          "/import",
          {
            url: response["file_url"],
          },
          userInfo.token
        );
        if (putUrlResponse) {
          setModalConfirmVisible(true);
          setPercent(100);
        }
      }
    }
  };

  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload() {
      return false;
    },
    onChange(info: any) {
      setPercent(0);
      setFile(undefined);
      if (info.file.size > 3145728) {
        return false;
      }
      if (
        info.file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        info.file.type === "application/vnd.ms-excel"
      ) {
        setFile(info.file);
        const { status } = info.file;

        var i = 0;
        var varName = function () {
          setPercent(i * 10);
          i++;
          if (i === 10) {
            clearInterval(intervalId);
          }
        };
        upload(info.file);
        const intervalId = setInterval(varName, 50);

        if (status !== "uploading") {
        }
        if (status === "done") {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    },
    onDrop(e: any) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <div>
      <Modal
        title={t("upload_list_title")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="text-center  modal-upload-right"
        footer={null}
        style={{
          float: "right",
          minHeight: "100%",
          position: "relative",
        }}
      >
        <Dragger {...props} style={{ height: "80%" }}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined style={{ color: "#AF9FAC" }} />
          </p>
          <p className="ant-upload-text">Kéo và thả tệp tin vào khung này</p>
          <p className="ant-upload-hint">
            Lưu ý chỉ được tải lên 1 tệp tin cho 1 lần tài. Tệp phải là file
            excel và có định dạng đuôi “.xlsx”.
          </p>
        </Dragger>
        <br />
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>
            {t("choose_document_upload")}
          </Button>
        </Upload>
        {file ? (
          <div
            style={{
              border: "1px solid #70DB8E",
              height: "52px",
              background: "#F3FDF2",
              marginTop: "20px",
              textAlign: "left",
              padding: "5px 5px 0px 5px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {t("file")} : {file?.name}
              </div>
              <div>
                {t("upload")} {percent} %{" "}
              </div>
            </div>
            <Progress
              percent={percent}
              status="success"
              showInfo={false}
              className="upload-progress"
            />
          </div>
        ) : null}

        <div
          style={{
            color: "#DB7339",
            position: "fixed",
            bottom: "50px",
          }}
        >
          1.Đảm bảo mẫu excel của bạn đúng định dạng của UrBox.
          <br />
          2.Đảm bảo mã vận chuyển đã được cập nhập trong tệp tin.
        </div>
      </Modal>
      <Modal
        title={t("notification")}
        visible={modalConfirmVisible}
        onCancel={() => {
          setModalConfirmVisible(false);
          setIsModalVisible(false);
        }}
        className="confirm-popup out-of-stock-modal text-left modal-upload-confirm"
        footer={null}
      >
        <span style={{ marginLeft: "10px" }}>
          {" "}
          Tải file thành công
          <span style={{ color: "#E12A4E" }}></span>
        </span>
        <br />{" "}
        <ul style={{ listStyleType: "disc", marginLeft: "30px" }}>
          <li>
            Dữ liệu đang được cập nhật có thế mất vài phút. Các mã vận chuyển
            đều có thể chỉnh sửa sau khi đã cập nhập cho UrBox.
          </li>
        </ul>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            onClick={() => {
              setModalConfirmVisible(false);
              setIsModalVisible(false);
              window.location.reload();
            }}
            style={{
              background: "#1B903C",
              color: "white",
              border: "1px solid #1B903C",
              width: "90%",
              height: "40px",
              borderRadius: "4px",
            }}
          >
            {t("understood")}
          </button>
        </div>
      </Modal>
    </div>
  );
}
