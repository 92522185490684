import MyService from "./DropShipService";

const DropShipApi = {
  getList: async (credentials, token) => {
    try {
      const result = await MyService.getRequestData(
        "/order-ds",
        credentials,
        token
      );

      return result;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  update: async (credentials, token) => {
    try {
      const result = await MyService.putRequestData(
        "/order-ds",
        credentials,
        token
      );

      return result;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  getData: async (credentials) => {
    try {
      const result = await MyService.putRequestData("/data", credentials);

      return result;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
};

export default DropShipApi;
