import OverViewCard from "../../components/manage-order/OverViewCard";
import { useTranslation } from "react-i18next";
import SearchImg from "../../images/search.svg";
import BackImg from "../../images/back.svg";
import DataTable from "../../components/manage-order/DataGrid";
import UploadModal from "../../components/manage-order/UploadModal";
import { useState } from "react";
import SettingImg from "../../images/setting.svg";
import SettingChildImg from "../../images/setting-child.svg";
import { Checkbox, Button, Modal } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import "./style.scss";
import { OrderListBy } from "../../entity/entity";

export default function ManageOrderPage() {
  const { t } = useTranslation();
  const [titlePage, setTitlePage] = useState("");
  const [typeViewDataGrid, setTypeViewDataGrid] = useState<
    OrderListBy | undefined
  >(undefined);

  const [orderInMonthDisplay, setOrderInMonthDisplay] = useState(true);
  const [mustAddDeliverCodeDisplay, setMustAddDeliverCodeDisplay] =
    useState(true);
  const [orderNewDisplay, setOrderNewDisplay] = useState(true);
  const [orderFinishInMonthDisplay, setOrderFinishInMonthDisplay] =
    useState(true);
  const [orderDeliverySuccessDisplay, setOrderDeliverySuccessDisplay] =
    useState(true);
  const [orderFailDisplay, setOrderFailDisplay] = useState(false);
  const [orderReturnDisplay, setOrderReturnDisplay] = useState(false);
  const [totalItemChecked, setTotalItemChecked] = useState(4);
  const [checkAll, setcheckAll] = useState(true);

  const orderInMonthDisplayChange = () => {
    setOrderInMonthDisplay(orderInMonthDisplay ? false : true);
    setTotalItemChecked(
      orderInMonthDisplay ? totalItemChecked - 1 : totalItemChecked + 1
    );
  };
  const orderDeliverCodeDisplayChange = () => {
    setMustAddDeliverCodeDisplay(mustAddDeliverCodeDisplay ? false : true);
    setTotalItemChecked(
      mustAddDeliverCodeDisplay ? totalItemChecked - 1 : totalItemChecked + 1
    );
  };
  const orderNewDisplayChange = () => {
    setOrderNewDisplay(orderNewDisplay ? false : true);
    setTotalItemChecked(
      orderNewDisplay ? totalItemChecked - 1 : totalItemChecked + 1
    );
  };
  const orderFinishInMonthDisplayChange = () => {
    setOrderFinishInMonthDisplay(orderFinishInMonthDisplay ? false : true);
    setTotalItemChecked(
      orderFinishInMonthDisplay ? totalItemChecked - 1 : totalItemChecked + 1
    );
  };
  const orderDeliverySuccessDisplayChange = () => {
    setOrderDeliverySuccessDisplay(orderDeliverySuccessDisplay ? false : true);
    setTotalItemChecked(
      orderDeliverySuccessDisplay ? totalItemChecked - 1 : totalItemChecked + 1
    );
  };
  const orderFailDisplayChange = () => {
    setOrderFailDisplay(orderFailDisplay ? false : true);
    setTotalItemChecked(
      orderFailDisplay ? totalItemChecked - 1 : totalItemChecked + 1
    );
  };
  const orderCancelDisplayChange = () => {
    setOrderReturnDisplay(orderReturnDisplay ? false : true);
    setTotalItemChecked(
      orderReturnDisplay ? totalItemChecked - 1 : totalItemChecked + 1
    );
  };
  const checkAllChange = () => {
    setcheckAll(checkAll ? false : true);
    if (!checkAll) {
      setOrderInMonthDisplay(true);
      setMustAddDeliverCodeDisplay(true);
      setOrderNewDisplay(true);
      setOrderFinishInMonthDisplay(true);
      setOrderDeliverySuccessDisplay(true);
      setOrderFailDisplay(true);
      setOrderReturnDisplay(true);
      setTotalItemChecked(6);
    } else {
      setOrderInMonthDisplay(false);
      setMustAddDeliverCodeDisplay(false);
      setOrderNewDisplay(false);
      setOrderFinishInMonthDisplay(false);
      setOrderDeliverySuccessDisplay(false);
      setOrderFailDisplay(false);
      setOrderReturnDisplay(false);
      setTotalItemChecked(0);
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [uploadModalShow, setUploadModalShow] = useState<boolean | undefined>(
    undefined
  );
  function showUploadModal() {
    setUploadModalShow(uploadModalShow ? false : true);
  }

  function onchangeTypeView(TypeView: OrderListBy | undefined, title: string) {
    if (TypeView === typeViewDataGrid) {
      setTypeViewDataGrid(undefined);
    } else {
      setTypeViewDataGrid(TypeView);
    }
    setTitlePage(t(title));
  }

  const [keySearch, setKeySearch] = useState("");
  const search = (e: any) => {
    setKeySearch(e.target.value === "" ? "" : e.target.value.trim());
  };

  const [exportData, setExportData] = useState<boolean | undefined>(undefined);

  const exportDataTable = () => {
    setExportData(exportData ? false : true);
  };

  return (
    <div style={{ backgroundColor: "none" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: typeViewDataGrid === undefined ? "30px" : "60px",
        }}
      >
        <div>
          <div
            style={{
              fontSize: typeViewDataGrid === undefined ? "20px" : "14px",
              display: "flex",
            }}
          >
            {t("manage_order")}
            <span
              style={{
                display: typeViewDataGrid === undefined ? "none" : "block",
                marginLeft: "5px",
              }}
            >
              /
            </span>
          </div>
          <p
            style={{
              fontSize: "20px",
              lineHeight: "40px",
              display: typeViewDataGrid === undefined ? "none" : "block",
            }}
          >
            <a href="/manage-order">
              <img
                src={BackImg}
                alt="back"
                style={{ marginRight: "15px", height: "15px" }}
              />
            </a>
            {t(titlePage)}
          </p>
        </div>

        <div
          style={{
            width: "116px",
            height: "40px",
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            paddingLeft: "10px",
            borderRadius: "2px",
            cursor: "pointer",
          }}
          onClick={showModal}
        >
          <div
            style={{
              position: "relative",
              width: "15px",
              height: "15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={SettingImg} alt="option" height="14px" />
            <img
              src={SettingChildImg}
              alt="option"
              style={{ position: "absolute", left: "3px" }}
            />
          </div>
          <div style={{ marginLeft: "10px", lineHeight: "40px" }}>
            {t("option")}
          </div>
        </div>
      </div>
      <div
        style={{
          display: typeViewDataGrid === undefined ? "flex" : "none",
          justifyContent: "flex-start",
          flexFlow: "row wrap",
        }}
      >
        {orderInMonthDisplay ? (
          <OverViewCard
            title={t("total_order_in_month")}
            total={100}
            TypeView={OrderListBy.TOTAL_IN_MONTH}
            currentTypeView={typeViewDataGrid}
            onchangeTypeView={onchangeTypeView}
            key={0}
          />
        ) : null}

        {mustAddDeliverCodeDisplay ? (
          <OverViewCard
            title={t("must_add_deliver_code")}
            total={100}
            TypeView={OrderListBy.ADD_DELIVER_CODE}
            currentTypeView={typeViewDataGrid}
            onchangeTypeView={onchangeTypeView}
            key={1}
          />
        ) : null}
        {orderNewDisplay ? (
          <OverViewCard
            title={t("order_new")}
            total={100}
            TypeView={OrderListBy.NEW}
            currentTypeView={typeViewDataGrid}
            onchangeTypeView={onchangeTypeView}
            key={2}
          />
        ) : null}
        {false && orderFinishInMonthDisplay ? (
          <OverViewCard
            title={t("order_finish_in_month")}
            total={100}
            TypeView={OrderListBy.FINISH_IN_MONTH}
            currentTypeView={typeViewDataGrid}
            onchangeTypeView={onchangeTypeView}
            key={3}
          />
        ) : null}
        {orderDeliverySuccessDisplay ? (
          <OverViewCard
            title={t("order_delivery_success")}
            total={100}
            TypeView={OrderListBy.SUCCESS}
            currentTypeView={typeViewDataGrid}
            onchangeTypeView={onchangeTypeView}
            key={4}
          />
        ) : null}
        {orderFailDisplay ? (
          <OverViewCard
            title={t("order_fail")}
            total={100}
            TypeView={OrderListBy.FAIL}
            currentTypeView={typeViewDataGrid}
            onchangeTypeView={onchangeTypeView}
            key={5}
          />
        ) : null}
        {orderReturnDisplay ? (
          <OverViewCard
            title={t("order_cancel")}
            total={100}
            TypeView={OrderListBy.RETURN}
            currentTypeView={typeViewDataGrid}
            onchangeTypeView={onchangeTypeView}
            key={6}
          />
        ) : null}
      </div>
      <div
        style={{
          width: "100%",
          height: "4px",
          backgroundColor: "#EAEAF4",
          marginTop: "20px",
        }}
      ></div>
      <div
        style={{
          width: "100%",
          height: "40px",

          marginTop: "20px",

          display: "flex",

          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            maxWidth: "650px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            border: "1px solid #D9D9D9",
            borderRadius: "4px",
            backgroundColor: "white",
            paddingTop: "0",
            paddingLeft: "5px",
            paddingRight: "15px",
          }}
        >
          <div
            style={{
              maxWidth: "600px",
              minWidth: "200px",
              height: "39px",
              borderRight: "1px solid #D9D9D9",
            }}
          >
            <input
              type="text"
              style={{
                width: "100%",
                border: "none",
                height: "36px",
                outline: "none",
              }}
              onChange={search}
              placeholder={t("order_code") + "," + t("phone_number")}
            />
          </div>
          <img
            src={SearchImg}
            alt="search"
            height="12.5px"
            style={{ marginLeft: "13px" }}
          />
        </div>
        <div>
          <Button
            type="default"
            icon={<DownloadOutlined />}
            style={{ color: "#007BFF", marginRight: "15px", height: "40px" }}
            onClick={exportDataTable}
          >
            {t("download_list")}
          </Button>
          <Button
            type="default"
            icon={<UploadOutlined />}
            onClick={showUploadModal}
            style={{ height: "40px" }}
          >
            {t("upload_list")}
          </Button>
        </div>
      </div>
      <br />
      <DataTable
        typeView={typeViewDataGrid}
        search={keySearch}
        export={exportData}
      />
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={240}
        style={{ float: "right", marginRight: "20px" }}
        title={[
          <div
            style={{
              width: "100%",
              textAlign: "center",
              height: "39px",
              lineHeight: "39px",
            }}
            key="modal-content"
          >
            {t("option")}
          </div>,
        ]}
        footer={[
          <Button key="back" onClick={handleOk} style={{ width: "216px" }}>
            {t("apply")}
          </Button>,
        ]}
      >
        <div className="overview-item">
          {t("total_order_in_month")}
          <Checkbox
            onChange={orderInMonthDisplayChange}
            checked={orderInMonthDisplay}
            key="item0"
          />
        </div>
        <div className="overview-item">
          {t("must_add_deliver_code")}
          <Checkbox
            onChange={orderDeliverCodeDisplayChange}
            checked={mustAddDeliverCodeDisplay}
            key="item1"
          />
        </div>
        <div className="overview-item">
          {t("order_new")}
          <Checkbox
            onChange={orderNewDisplayChange}
            checked={orderNewDisplay}
            key="item2"
          />
        </div>
        <div className="overview-item" style={{ display: "none" }}>
          {t("order_finish_in_month")}
          <Checkbox
            onChange={orderFinishInMonthDisplayChange}
            checked={orderFinishInMonthDisplay}
            key="item3"
          />
        </div>
        <div className="overview-item">
          {t("order_delivery_success")}
          <Checkbox
            onChange={orderDeliverySuccessDisplayChange}
            checked={orderDeliverySuccessDisplay}
            key="item4"
          />
        </div>
        <div className="overview-item">
          {t("order_fail")}
          <Checkbox
            onChange={orderFailDisplayChange}
            checked={orderFailDisplay}
            key="item5"
          />
        </div>
        <div className="overview-item">
          {t("order_cancel")}
          <Checkbox
            onChange={orderCancelDisplayChange}
            checked={orderReturnDisplay}
            key="item6"
          />
        </div>
        <div className="overview-item">
          {totalItemChecked} items
          <Checkbox onChange={checkAllChange} checked={checkAll} key="item7" />
        </div>
      </Modal>
      <UploadModal show={uploadModalShow} />
    </div>
  );
}
