import { useState } from "react";
import "antd/dist/antd.css";
import SwitchSelector from "react-switch-selector";
import i18n from "../locales/i18n";
import Cookies from "js-cookie";

const options = [
  {
    label: "VN",
    value: "vn",
    selectedBackgroundColor: "white",
    innerHeight: 50,
  },
  {
    label: "EN",
    value: "en",
    selectedBackgroundColor: "white",
  },
];

const LanguageOption = () => {
  const onChange = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    Cookies.set("i18next", lang);
  };
  const currentLang = i18n.language;

  const [language, setLanguage] = useState(currentLang);
  const initialSelectedIndex = options.findIndex(
    ({ value }) => value === language
  );
  return (
    <div  style={{width: 80, height: 26}}>
      <SwitchSelector
        onChange={onChange}
        options={options}
        initialSelectedIndex={initialSelectedIndex}
        backgroundColor={"#EFF0F9"}
        fontColor={"#AF9FAC"}
        border="2px solid #EAEAF4"
        fontSize="13"
        selectedFontColor={"#A8679B"}
        width="80px"
      />
      </div>
  );
};

export default LanguageOption;
