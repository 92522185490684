export interface User {
  token: string;
  username: string;
  expired: number;
  tax_id: number;
  type: number;
  remember_password: boolean;
}

export enum OrderListBy {
  TOTAL_IN_MONTH = "TOTAL_IN_MONTH",
  ADD_DELIVER_CODE = "ADD_DELIVER_CODE",
  NEW = "NEW",
  FINISH_IN_MONTH = "FINISH_IN_MONTH",
  SUCCESS = "SUCCESS",
  RETURN = "RETURN",
  FAIL = "FAIL",
  READY = "READY",
  DELIVERING = "DELIVERING",
}

export enum ProcessStatus {
  DELIVERED = 1,
  RETURNED = 2,
  FAILED = 3,
  OUT_IN_MONTH = 4,
  CONFIRMED = 5,
  OUTOFSTOCK = 6,
  READY = 7,
  DELIVERING = 8,
  WAITING = 9,
}
export enum UrBoxProcessStatus {
  PROCESSING = 1,
  DELIVERED = 2,
  SUCCESS = 3,
  RETURNED = 4,
  WAITING = 5
}

export const ProcessStatusText = (status: number) => {
  if (status === 1) return "Thành công";
  if (status === 2) return "Trả hàng";
  if (status === 3) return "Thất bại";
  if (status === 4) return "Hết hàng trong tháng";
  if (status === 5) return "Đã xác nhận";
  if (status === 6) return "Hết hàng";
  if (status === 7) return "Có hàng";
  if (status === 8) return "Đang giao hàng";
  if (status === 9) return "Chờ có hàng";
  else return "Cần nhập mã đơn";
};
