import React, { useState } from "react";
import { Layout } from "antd";
import TopicMenu from "../components/manage-order/ToppicMenu";
import "antd/dist/antd.min.css";
import NavBar from "../components/manage-order/NavBar";
import SideBar from "../components/manage-order/Sidebar";
import { useTranslation } from "react-i18next";
import ManageOrderPage from "../pages/manage-order/Index";

function ManageOrder() {
  const { t } = useTranslation();
  const topics = [t("manage_order")];
  const [contentIndex, setContentIndex] = useState(0);
  const [selectedKey, setSelectedKey] = useState("0");
  const changeSelectedKey = (event) => {
    const key = event.key;
    setSelectedKey(key);
    setContentIndex(+key);
  };

  const Menu = (
    <TopicMenu
      topics={topics}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
    />
  );
  return (
    <div className="App" >
      <NavBar menu={Menu} />
      <Layout style={{backgroundColor:"#EFF0FA"}}>
        <SideBar menu={Menu} />
        <Layout.Content className="content" style={{ marginTop: "60px" }}>
          <ManageOrderPage />
        </Layout.Content>
      </Layout>
    </div>
  );
}
export default ManageOrder;
