import { Layout } from "antd";
//import "./SideBar.css"
const SideBar = ({ menu }) => {
  return (
    <Layout.Sider
      className="sidebar"
      breakpoint={"lg"}
      theme="light"
      collapsedWidth={0}
      trigger={null}
      style={{minHeight: "90vh",
        marginRight: "24px",backgroundColor:"#F5F7FB",marginTop:"12px"}}
    >
      {menu}
   </Layout.Sider>
   );
};
export default SideBar;