import { useState } from "react";
import { ReactComponent as SquareImg } from "../../images/square.svg";
import { ReactComponent as PencilImg } from "../../images/pencil.svg";
import { ReactComponent as NextImg } from "../../images/next.svg";
import { useTranslation } from "react-i18next";
import DropShipApi from "../../api/DropShipApi";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

interface Prop {
  code: string;
  data: any;
  spin: () => void;
  spinFail: () => void;
}
export default function UpdateDeliveryCode(prop: Prop) {
  const { t } = useTranslation();
  const [code, setCode] = useState(prop.code);
  const [isEdit, setEdit] = useState(false);
  const userInfo = useSelector((state: AppState) => state.user);

  const save = async () => {
    const response = await DropShipApi.update(
      {
        dropship_code: code,
        id: prop.data.id,
      },
      userInfo.token
    );
    if (response?.id && response.id) {
      prop.spin();
      setTimeout(() => {
        setEdit(false);
      }, 1000);
    } else {
      prop.spinFail();
    }
  };

  return (
    <div
      style={{
        background: "#F0F2F5",
        width: "180px",
        height: "40px",
        borderRadius: "2px",
        display: "flex",
      }}
    >
      <input
        style={{
          height: "40px",
          width: "140px",
          outline: "none",
          backgroundColor: "transparent",
          paddingLeft: "3px",
          border: "1px solid #D9D9D9",
        }}
        placeholder={t("type_deliver_code")}
        onChange={(e) => setCode(e.target.value.toUpperCase())}
        onInput={(e) => setEdit(true)}
        value={code}
      />
      {!isEdit ? (
        <div
          style={{
            backgroundColor: "#ADB5BD",
            border: "1px solid #ADB5BD",
            width: "40px",
            height: "100%",
            borderRadius: "0px 2px 2px 0px",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
          }}
        >
          {prop.code ? <SquareImg /> : null}
          {prop.code ? (
            <PencilImg
              style={{ position: "absolute", top: "12px", left: "16px" }}
            />
          ) : null}
          {prop.code ? null : <NextImg />}
        </div>
      ) : null}
      {isEdit ? (
        <button
          style={{
            backgroundColor: "#720D5D",
            border: "1px solid #720D5D",
            width: "40px",
            height: "100%",
            borderRadius: "0px 2px 2px 0px",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
            cursor: "pointer",
          }}
          onClick={save}
        >
          <NextImg />
        </button>
      ) : null}
    </div>
  );
}
