import MyService from "./Service";

const UserApi = {
  login: async (credentials, token) => {
    try {
      const result = await MyService.putRequestData(
        "/user/login",
        credentials,
        token
      );

      return result;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  send_email_reset_password: async (credentials) => {
    try {
      const result = await MyService.putRequestData(
        "/user/send-email-reset-password",
        credentials
      );

      return result;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  reset_password: async (credentials) => {
    try {
      const result = await MyService.putRequestData(
        "/user/reset-password",
        credentials
      );

      return result;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  change_password: async (credentials, token) => {
    console.log(token);
    try {
      const result = await MyService.putRequestData(
        "/user/change-password",
        credentials,
        token
      );

      return result;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
};

export default UserApi;
