import { useTranslation } from "react-i18next";
import { isEmail } from "../../common/verify";
import { useState, useEffect } from "react";
import ErrorImg from "../../images/error.svg";
import "./Style.scss";
import { Form, Input, Button } from "antd";
import { ReactComponent as EmailImg } from "../../images/email.svg";
import UserApi from "../../api/UserApi";

export default function SendEmailConfirmReset() {
  const { t } = useTranslation();
  const [errorForm, setErrorForm] = useState(true);
  const [email, setEmail] = useState("");

  const emailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  useEffect(() => {
    setErrorForm(true);
    if (isEmail(email)) setErrorForm(false);
  }, [email]);

  const submitForm = async () => {
    const response = await UserApi.send_email_reset_password({
      email: email,
    });
    if (response?.success && response.success) {
      localStorage.setItem("emailSend", email);
      window.location.href = "/send-email-confirm-success";
    } else {
      setErrorForm(true);
    }
  };

  return (
    <div>
      <div
        style={{
          fontSize: 24,
          fontWeight: 700,
          fontFamily: "Roboto-Bold",
        }}
      >
        {t("reset_password")}
      </div>
      <div style={{ marginBottom: "20px" }}>{t("reset_password_text")}</div>

      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <Form.Item
          name="username"
          style={{
            marginBottom: "10px",
          }}
        >
          <Input
            placeholder={t("your_email")}
            style={{
              height: 40,
            }}
            suffix={<EmailImg />}
            onChange={emailChange}
          />
        </Form.Item>
        {errorForm && email !== "" ? (
          <div className="box-error">
            <span style={{ marginLeft: "17px", marginRight: "11px" }}>
              <img src={ErrorImg} alt="error" />
            </span>
            {t("email_not_exist")}
          </div>
        ) : null}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              height: "40px",
              backgroundColor: errorForm ? "" : "#720D5D",
              color: errorForm ? "" : "white",
            }}
            onClick={submitForm}
            disabled={errorForm}
          >
            {t("reset_password")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
