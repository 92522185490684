import { useTranslation } from "react-i18next";
import { ReactComponent as UserImg } from "../../images/user.svg";
import { Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Row, Col } from "antd";
import { useState, useEffect } from "react";
import UserApi from "../../api/UserApi";
import { useDispatch } from "react-redux";
import { userDidLogin } from "../../reducer/user";
import ErrorImg from "../../images/error.svg";

export default function Login() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [errorForm, setErrorForm] = useState(true);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [dataValid, setDataValid] = useState(true);
  const [rememberPassword, setRememberPassword] = useState(false);

  const userNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };
  const passwordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const changeRemember = (event: any) => {
    setRememberPassword(event.target.checked);
  };

  useEffect(() => {
    setErrorForm(true);
    if (password.trim().length > 0 && userName.trim().length > 0)
      setErrorForm(false);
  }, [password, userName]);

  const submitForm = async () => {
    const response = await UserApi.login({
      username: userName,
      password: password,
      remember_password: rememberPassword,
    });
    if (response?.data) {
      dispatch(userDidLogin(response.data));
      window.location.href = "/manage-order";
    } else {
      setDataValid(false);
    }
  };

  return (
    <div>
      <div
        style={{
          fontSize: 24,
          fontWeight: 700,
          fontFamily: "Roboto-Bold",
        }}
      >
        {t("login")}
      </div>
      <div style={{ marginBottom: "20px" }}>{t("login_description")}</div>

      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <Form.Item name="username">
          <Input
            placeholder={t("user_name")}
            style={{ height: 40 }}
            suffix={<UserImg />}
            onChange={userNameChange}
          />
        </Form.Item>

        <Form.Item name="password" style={{ marginBottom: "10px" }}>
          <Input.Password
            placeholder={t("password")}
            style={{ height: 40 }}
            onChange={passwordChange}
          />
        </Form.Item>
        {dataValid ? null : (
          <div className="box-error">
            <span style={{ marginLeft: "17px", marginRight: "11px" }}>
              <img src={ErrorImg} alt="error" />
            </span>
            Sai thông tin đăng nhập
          </div>
        )}

        <Form.Item name="remember" valuePropName="checked">
          <Row>
            <Col span={12}>
              <Checkbox
                style={{
                  borderColor: " #720D5D",
                }}
                onChange={changeRemember}
              >
                {t("remember_me")}
              </Checkbox>
            </Col>
            <Col
              span={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Link
                to="./send-email-confirm"
                style={{ fontStyle: "italic", color: "#3490FF" }}
              >
                {t("forgot_password")}
              </Link>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              height: "40px",
              backgroundColor: errorForm ? "" : "#720D5D",
              color: errorForm ? "" : "white",
            }}
            disabled={errorForm}
            onClick={submitForm}
          >
            {t("login")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
