import { BrowserRouter, Route, Routes } from "react-router-dom";
import Authentication from "./layouts/Authentication";
import ManageOrder from "./layouts/ManageOrder";
import i18n from "./locales/i18n";
import { I18nextProvider } from "react-i18next";
import { AppState } from "./store";
import { useDispatch, useSelector } from "react-redux";
import { userDidLogout } from "./reducer/user";

function App() {
  const dispatch = useDispatch();

  const pathNotLogin = [
    "/",
    "/send-email-confirm",
    "/send-email-confirm-success",
    "/reset-success",
    "/reset-password",
    "/expired",
  ];
  const pathLogin = [
    "/manage-order",
  ];

  function handleTokenInvalid() {
    dispatch(userDidLogout());
    window.location.href = "/";
  }

  const userInfo = useSelector((state: AppState) => state.user);
  const timeNow = Math.floor(Date.now() / 1000);

  if (pathLogin.includes(window.location.pathname)) {
    if (!userInfo) {
      handleTokenInvalid();
    }

    if (!userInfo.token) {
      handleTokenInvalid();
    }
    if (Number(userInfo.expired) < timeNow && !userInfo.remember_password) {
      handleTokenInvalid();
    }
  } 
  if (pathNotLogin.includes(window.location.pathname)) {
    if (
      userInfo &&
      (userInfo.remember_password || Number(userInfo.expired) > timeNow)
    ) {
      window.location.href = "/manage-order";
    }
  }

  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Authentication />}></Route>
          <Route
            path="/send-email-confirm"
            element={<Authentication />}
          ></Route>
          <Route
            path="/send-email-confirm-success"
            element={<Authentication />}
          ></Route>
          <Route path="/reset-password" element={<Authentication />}></Route>
          <Route path="/reset-success" element={<Authentication />}></Route>
          <Route path="/change-password" element={<Authentication />}></Route>
          <Route path="/expired" element={<Authentication />}></Route>
          <Route path="/manage-order" element={<ManageOrder />}></Route>
        </Routes>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
