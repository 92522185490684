import React, { useState } from "react";
import { Drawer } from "antd";
import "./NavBar.css";
import Logo from "../logo";
import Notification from "./Notification";
import AccountButton from "../manage-order/AccountButton";
import LanguageOption from "../LanguageOption";
const NavBar = ({ menu }) => {
  const [visible, setVisible] = useState(false);
  return (
    <div className="header">
      <nav className="navbar" style={{ boxShadow: " 0px 1px #F5F1F0" }}>
        <Drawer
          title="Topics"
          placement="left"
          onClick={() => setVisible(false)}
          onClose={() => setVisible(false)}
          visible={visible}
        >
          {menu}
        </Drawer>
        <div style={{ height: "40px" }}>
          <Logo />
        </div>
        <div className="navbar-right">
          <div>
            <Notification />
          </div>
          <div className="line-bg" style={{ marginLeft: "25px" }}></div>
          <div>
            <AccountButton />
          </div>
          <div className="line-bg"></div>
          <div>
            <LanguageOption />
          </div>
        </div>
      </nav>
    </div>
  );
};
export default NavBar;
