import axios from "axios";
const getHeaders = (token = "") => ({
  Accept: "application/json",
  "Content-Type": "application/json;charset=UTF-8",
  Authorization: `Bearer ${token}`,
});
const BASE_URL = process.env.REACT_APP_USER_API_URL;
const Service = {
  async getRequestData(url: string, params?: Record<string, any>, token = "") {
    try {
      const response = await axios({
        method: "get",
        url: BASE_URL + url,
        headers: getHeaders(token),
        params,
      });

      return response.data;
    } catch (e) {
      console.log(e);

      return null;
    }
  },
  async postRequestData(url: string, data: Record<string, any>, token = "") {
    let result;
    await axios
      .post(BASE_URL + url, data, { headers: getHeaders(token) })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => (result = error));
    return result;
  },

  async putRequestData(url: string, data: Record<string, any>, token?: string) {
    console.log({
      headers: getHeaders(token),
    });
    const result = await axios
      .put(BASE_URL + url, data, {
        headers: getHeaders(token),
      })
      .then((response) => response.data)
      .catch((error) => console.log(error));
    return result;
  },
  async deleteRequestData(url: string) {
    let result;
    await axios
      .delete(BASE_URL + url, {
        headers: getHeaders(),
      })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => (result = error));
    return result;
  },
};

export default Service;
