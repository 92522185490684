import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import translationEN from "./en/translation";
import translationVI from "./vn/translation";
import Cookies from "js-cookie";

const currentLocale = Cookies.get("i18next") || "vn";
// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  vn: {
    translation: translationVI,
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    lng: currentLocale,
    fallbackLng: "vn",
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
