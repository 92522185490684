import { useTranslation } from "react-i18next";
import ErrorImg from "../../images/error.svg";
import { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import { useLocation } from "react-router-dom";
import UserApi from "../../api/UserApi";
import { AppState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { userDidLogout } from "../../reducer/user";

export default function ChangePassword() {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errorForm, setErrorForm] = useState(true);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorPasswordConfirm, setErrorPasswordConfirm] = useState(false);
  const [errorCurrentPassword, setErrorCurrentPassword] = useState(false);
  const search = useLocation().search;
  const [resetStatus, setResetStatus] = useState(true);
  const userInfo = useSelector((state: AppState) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    function veryfiPassWord() {
      setErrorPassword(false);
      setErrorPasswordConfirm(false);
      setErrorForm(false);
      setErrorCurrentPassword(false);

      if (password.length < 6 || password.length > 255) {
        setErrorPassword(true);
        setErrorForm(true);
        return false;
      }

      if (currentPassword.length < 6 || currentPassword.length > 255) {
        setErrorCurrentPassword(true);
        setErrorForm(true);
        return false;
      }

      const upperCaseLetters = /[A-Z]/g;
      if (!password.match(upperCaseLetters)) {
        setErrorPassword(true);
        setErrorForm(true);
        return false;
      }
      if (!currentPassword.match(upperCaseLetters)) {
        setErrorCurrentPassword(true);
        setErrorForm(true);
        return false;
      }

      const numbers = /[0-9]/g;
      if (!currentPassword.match(numbers)) {
        setErrorCurrentPassword(true);
        setErrorForm(true);
        return false;
      }
      if (!password.match(numbers)) {
        setErrorPassword(true);
        setErrorForm(true);
        return false;
      }

      const lowerCaseLetters = /[a-z]/g;
      if (!password.match(lowerCaseLetters)) {
        setErrorPassword(true);
        setErrorForm(true);
        return false;
      }
      if (!currentPassword.match(lowerCaseLetters)) {
        setErrorCurrentPassword(true);
        setErrorForm(true);
        return false;
      }

      if (password.localeCompare(passwordConfirm) !== 0) {
        setErrorPasswordConfirm(true);
        setErrorForm(true);
        return false;
      }
    }
    veryfiPassWord();
  }, [password, passwordConfirm, currentPassword]);

  const typePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const typeCurrentPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(event.target.value);
  };

  const typePasswordConfirm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(event.target.value);
  };
  const submitForm = async () => {
    const response = await UserApi.change_password(
      {
        password: password,
        current_password: currentPassword,
        token: userInfo.token,
      },
      userInfo.token
    );
    if (response?.success && response.success === true) {
      dispatch(userDidLogout());
      window.location.href = "/reset-success";
    } else {
      setResetStatus(false);
    }
  };

  return (
    <div>
      <div
        style={{
          fontSize: 24,
          fontWeight: 700,
          fontFamily: "Roboto-Bold",
        }}
      >
        {t("change_password")}
      </div>
      <div style={{ marginBottom: "20px" }}>{t("reset_password_text")}</div>

      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <Form.Item name="current_password" style={{ marginBottom: "10px" }}>
          <Input.Password
            placeholder={t("current_password")}
            style={{ height: 40 }}
            onChange={typeCurrentPassword}
          />
        </Form.Item>
        {errorCurrentPassword && currentPassword !== "" ? (
          <div className="box-error">
            <span style={{ marginLeft: "17px", marginRight: "11px" }}>
              <img src={ErrorImg} alt="error" />
            </span>
            Mật khẩu chưa đúng cú pháp.
          </div>
        ) : null}
        <Form.Item name="password" style={{ marginBottom: "10px" }}>
          <Input.Password
            placeholder={t("new_password")}
            style={{ height: 40 }}
            onChange={typePassword}
          />
        </Form.Item>
        {errorPassword && password !== "" ? (
          <div className="box-error">
            <span style={{ marginLeft: "17px", marginRight: "11px" }}>
              <img src={ErrorImg} alt="error" />
            </span>
            Mật khẩu chưa đúng cú pháp.
          </div>
        ) : null}
        <Form.Item name="passwordConfirm" style={{ marginBottom: "10px" }}>
          <Input.Password
            placeholder={t("confirm_new_password")}
            style={{ height: 40 }}
            onChange={typePasswordConfirm}
          />
        </Form.Item>
        {errorPasswordConfirm && passwordConfirm !== "" ? (
          <div className="box-error">
            <span style={{ marginLeft: "17px", marginRight: "11px" }}>
              <img src={ErrorImg} alt="error" />
            </span>
            Mật khẩu không đồng nhất.
          </div>
        ) : null}
        {resetStatus !== true ? (
          <div className="box-error">
            <span style={{ marginLeft: "17px", marginRight: "11px" }}>
              <img src={ErrorImg} alt="error" />
            </span>
            Xảy ra lỗi,vui lòng xem lại
          </div>
        ) : null}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              height: "40px",
              backgroundColor: errorForm ? "" : "#720D5D",
              color: errorForm ? "" : "white",
            }}
            disabled={errorForm}
            onClick={submitForm}
          >
            {t("change_password")}
          </Button>
        </Form.Item>
      </Form>
      <div
        style={{
          backgroundColor: "#EAEAF4",
          marginTop: "13px",
          marginBottom: "13px",
          height: "1px",
        }}
      ></div>
      <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
        <div
          style={{
            backgroundColor: "#37B34A",
            borderRadius: "50%",
            height: "8px",
            width: "8px",
            display: "block",
            marginRight: "12px",
          }}
        ></div>
        <div>Ít nhất 6 ký tự tối đa 255 ký tự.</div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            backgroundColor: "#F5222D",
            borderRadius: "50%",
            height: "8px",
            width: "8px",
            display: "block",
            marginRight: "12px",
          }}
        ></div>
        <div>Gồm chữ hoa, chữ thường và số.</div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            backgroundColor: "#F5222D",
            borderRadius: "50%",
            height: "8px",
            width: "8px",
            display: "block",
            marginRight: "12px",
          }}
        ></div>
        <div>Mật khẩu không được để trống.</div>
      </div>
    </div>
  );
}
