import Arrrow from "../../images/arrrow.svg";
import { OrderListBy } from "../../entity/entity";
import "./style.scss";
import DropShipApi from "../../api/DropShipApi";
import { useState, useEffect } from "react";
import { AppState } from "../../store";
import { useSelector } from "react-redux";

interface Prop {
  title: string;
  total: number;
  TypeView: OrderListBy | undefined;
  currentTypeView: OrderListBy | undefined;
  onchangeTypeView: (TypeView: OrderListBy | undefined, title: string) => void;
}
export default function OverViewCard(props: Prop) {
  function onchangeTypeView() {
    props.onchangeTypeView(props.TypeView, props.title);
  }
  const [count, setCount] = useState(0);
  const userInfo = useSelector((state: AppState) => state.user);

  async function getData() {
    var url = "/tong_don_thang_nay";
    switch (props.TypeView) {
      case OrderListBy.ADD_DELIVER_CODE:
        url = "/can_nhap_ma_van_don";
        break;
      case OrderListBy.NEW:
        url = "/don_moi_nhan";
        break;
      case OrderListBy.SUCCESS:
        url = "/don_da_van_chuyen";
        break;
      case OrderListBy.FAIL:
        url = "/don_khong_thanh_cong";
        break;
      case OrderListBy.RETURN:
        url = "/don_hoan_tra";
        break;
      default:
        url = "/tong_don_thang_nay";
    }
    const response = await DropShipApi.getData({
      url: process.env.REACT_APP_DATA_URL + url + `?tax_id=${userInfo.tax_id}&status=2`,
    });
    if (response?.data) {
      setCount(response.data);
    }
  }
  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      style={{
        width: "230px",
        height: "72px",
        borderRadius: "4px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
        backgroundColor: "white",
        paddingLeft: "15px",
        paddingTop: "8px",
        paddingRight: "12px",
        marginTop: "32px",
        marginLeft: "15px",
        cursor: "pointer",
        border:
          props.TypeView === props.currentTypeView ? "1px solid #720D5D" : "",
      }}
      onClick={onchangeTypeView}
      className="over-view-item"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "#8C8C8C",
        }}
      >
        <span
          style={{
            color: props.TypeView === props.currentTypeView ? "#720D5D" : "",
          }}
        >
          {props.title}
        </span>

        <img
          src={Arrrow}
          alt="view detail"
          style={{ cursor: "pointer" }}
          onClick={onchangeTypeView}
        />
      </div>

      <p style={{ fontSize: "24px" }}>{count}</p>
    </div>
  );
}
